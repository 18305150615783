import { graphql, HeadProps } from 'gatsby';
import React from 'react';
import { LatestNewsPageQueryQuery } from '../../graphql-types';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import { isObjectHasValues } from '../utils/validators';
import LatestNewsView from './views/latest-news-view';

export interface LatestNewsPageContext {
  limit: number;
  skip: number;
  numPages: number;
  currentPage: number;
  slug: string;
  tags: string[];
}

export interface LatestNewsPageProps {
  data: LatestNewsPageQueryQuery;
  pageContext: LatestNewsPageContext;
}

export function Head(props: HeadProps<LatestNewsPageQueryQuery>) {
  const { data } = props;
  const {
    CraftGQL: { entry }
  } = data;
  if (!isObjectHasValues(entry)) return null;
  const { seo } = entry;
  return <SEO pageUrl={props.location.pathname} seo={seo} />;
}

const LatestNewsPage = (props: LatestNewsPageProps) => {
  const { data, pageContext } = props;
  const parentUrl = ``;
  console.log('DATA', data, props);
  return <LatestNewsView data={data?.CraftGQL} parentUrl={parentUrl} pageContext={pageContext} />;
};

export const query = graphql`
  query LatestNewsPageQuery($skip: Int!, $limit: Int!, $slug: String!) {
    CraftGQL {
      tags {
        ... on CraftGQL_newsTags_Tag {
          id
          title
          slug
        }
      }
      entry(slug: [$slug], site: "movingLoop") {
        ... on CraftGQL_mlLatestNewTemplate_mlLatestNewTemplate_Entry {
          heroBackgroundImage {
            url
          }
          heroIcon {
            url
          }
          heroBackgroundColor
          heroTitle
          seo {
            title
            description
          }
        }
      }
      entries(
        inReverse: true
        orderBy: "postDate"
        limit: $limit
        offset: $skip
        section: "mlLatestNews"
        site: "movingLoop"
      ) {
        ... on CraftGQL_mlLatestNews_default_Entry {
          id
          slug
          heroBackgroundImage {
            url
          }
          title
          postDate
          newsTags {
            ... on CraftGQL_newsTags_Tag {
              id
              title
            }
          }
        }
      }
    }
  }
`;

LatestNewsPage.Layout = Layout;
export default LatestNewsPage;
